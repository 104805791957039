/// <reference path="../../typings/graphql.d.ts"/>

import * as R from 'ramda'

import React from 'react'
import styled, { ThemeProvider } from 'styled-components'
import { MdsCarousel} from '../components/carousel'
import { graphql, Link } from 'gatsby'

import Layout, { extractData, THEMES } from '../components/layout'
import { Page, Row, LinkItem, List, Fader, SmartLink, MovieVM, Routing, isMobile, isSSR, ShowsVM } from '../components/utils'

import MovieList from '../components/movie-list'
import Section, { SubSection, Subtitle } from '../components/section'
import MovieShowList from '../components/movie-show-list'
import { Movie, Days, getShowsOnDay, Movies, Day, Cinema, Show, CINEMA_META, CinemaId, MovieId } from '../model/model'
import { Action, Actions } from '../model/actions'
import { Model } from '../model/model'
import MovieSummary, { renderTruncatedDescription } from '../components/movie-summary'
import { Dispatch } from 'redux'
import createStore, { FLAGS } from '../model/create-store'
import { Provider, connect } from 'react-redux';
import { Moment } from 'moment';
import ConnectedUpdater from '../components/updater';
import RImage from '../components/image';
import { Tracking } from '../model/utils';
import { Drawer } from '../components/drawer';
import { NewsletterForm } from '../components/newsletter-form';

const BannerImg = styled(RImage)`
display: flex;
align-self: center;
width: fit-content;
max-width: 100%;
@media (min-width: 700px) {
  display: none;
}
`
const BannerLink = styled(SmartLink)`
display: flex;
align-self: center;
width: fit-content;
max-width: 100%;
@media (min-width: 700px) {
  display: none;
}
`

const Panel = styled.div`
  display: none;
  @media (min-width: 700px) {
    display: block;
    position: sticky;
    top: 1rem;
    margin-top: 0.5rem;
    height: fit-content;
    flex: 1 45%;
  }
`

const MovieDrawerFooter = styled.div`
  display: block;
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  padding-top: 3rem;
  padding-bottom: 8vh;
  background: linear-gradient(rgba(18,18,18,0), rgba(18,18,18,0.5) 10%, rgba(18,18,18,1) 20%,rgba(18,18,18,1));
`

const GoToMovieCardButton = styled(Link)`
  display: block;
  width: 90%;
  margin-left: 5%;
  background-color: rgba(18, 18, 18, 1);
  border: 2px solid ${props => props.theme.colors.highlight };
  padding: 0.5rem;
  text-align: center;
`

interface MovieDrawerProps {
  highlight: Movie | null
  mainCinemaId: CinemaId
  onClick: () => void
}
const MovieDrawer: React.SFC<MovieDrawerProps> = ({highlight, mainCinemaId, onClick}) => {
  return (
    <Drawer isOpen={highlight != null} onClick={onClick}>
      { highlight == null
        ? undefined
        : <>
            <MovieSummary key={highlight.id} mainCinemaId={mainCinemaId} movie={highlight}/>
            <MovieDrawerFooter>
              <GoToMovieCardButton to={Routing.toMoviePage(highlight)}
                onClick={() => {Tracking.trackNavigation('home/showtimes/movie-drawer', highlight.title, highlight.id)}}>
                Vedi scheda film
              </GoToMovieCardButton>
            </MovieDrawerFooter>
          </>
      }
    </Drawer>
  )
}

const ListContainer = styled.div`
  flex: 1 55%;
`
const CinemaSubtitle = styled.div`
width: fit-content;
margin-top: -5px;
margin: auto;
margin-bottom: 5px;
padding: 0 10px;
text-align: center;
font-size: 0.8rem;
line-height: 1rem;
color: white;
text-transform: uppercase;
background-color: red;
`

const ShowtimeHeader = styled.div`
position: sticky;
top: 0;
z-index: 5;
background: linear-gradient(rgba(18,18,18,1), rgba(18,18,18,0.95) 80%, rgba(18,18,18,0.9) 90%, rgba(18,18,18,0));
& > h3 {
  color: rgba(18, 18, 18, 1);
  font-weight: 800;
  background-color: ${props => props.theme.colors.highlight};
}
@media(max-width: 699px) {
  & > h3 {
    margin-left: -5px;
    width: calc(100% + 10px);
  }
}
@media(min-width: 700px) {
  & > h3 {
    padding-top: 0.5rem;
  }
}
`
const truncateDescription = renderTruncatedDescription(80, 10, true)

interface CinemaDay {
  message: string | null
  movies: Movie[]
}


interface StaticProps {
  cinemas: Cinema[]
}

interface DynamicProps {
  selectedDay: Day
  nowShowing: Movie[]
  comingSoon: Movie[]
  events: Movie[]
  now: Moment
  activeDays: Day[]
  highlight: Movie | null
  cinemaMovies: CinemaDay[]
}

interface PageIntents {
  onHighlightMovie: (movie: Movie | null) => void
  onSelectDay: (day: Day) => void
}

type PageProps = StaticProps & DynamicProps

const IndexPage: React.SFC<PageProps & PageIntents> = ( {cinemas, cinemaMovies, now, activeDays, nowShowing, comingSoon, events, highlight, selectedDay, onSelectDay, onHighlightMovie} ) => {
  const {banner, slides} = cinemas[0]
  const today = Days.fromMoment(now)
  const tomorrow = Days.add(today, 1)
  const days = R.uniq([today, tomorrow, ...activeDays])
  const isMainCinemaOpen = cinemas[0].movies.length > 0

  const showSlides = cinemas[0].showSlides
  const showShowtimes = isMainCinemaOpen
  const showNowShowingReel = isMainCinemaOpen
  const showEventsReel = isMainCinemaOpen
  const showComingSoonReel = true

  return (
  <Layout showBackButton={false} cinemas={cinemas} title="">
    <Page>
      { banner != null &&
        <Section>
          <BannerLink mainCinemaId={cinemas[0].id} url={banner.link}
            onClick={() => {Tracking.trackNavigation('home/banner', banner.link!)}}>
            <BannerImg mode='eager' alt="Banner" image={cinemas[0].banner!.image}
              sizes={[{viewport: 370, size: 370}, {viewport: 700, size: 700}]}
              maxSize={720} />
          </BannerLink>
        </Section>
      }
      { cinemas[0].heroImage &&
          <img src={cinemas[0].heroImage} alt="Cinema Hero" style={{marginBottom: 16}}/>
      }
      { showSlides &&
        <Section>
          <MdsCarousel slides={slides} mainCinemaId={cinemas[0].id}
            onSlideClick={(slide, idx) => {Tracking.trackNavigation('home/slides', slide.link!, idx.toString())}}/>
        </Section>
      }
      { FLAGS.newsletter(cinemas[0].id) === 'newsletter' &&
        <Section>
          <NewsletterForm cinemaId={cinemas[0].id} formName="top" tags={[]} movie={null}
            onSuccess={(email, formName) => {
              Tracking.trackEngagement('home/subscribe-to-newsletter', formName)
            }}/>
        </Section>
      }
      { showNowShowingReel &&
        <Section title="In Programmazione">
          <MovieList look='slider1' lazy={false}
            items={MovieVM.computeMovieListForNowShowing(cinemas, nowShowing, today)}
            onCardClick={(item, idx) => {
              Tracking.trackNavigation('home/now-showing-list', item.movie.title, idx.toString())
            }}/>
        </Section>
      }
      { showShowtimes &&
      <Section title="Orario Spettacoli">
        <List wrap='wrap'>
         {
            days.map(day =>
              <LinkItem key={(isSSR() ? 'SSR' : '') + Days.toString(day)}
                  state={Days.isEqual(selectedDay)(day) ? 'selected' : 'normal'}
                  onClick={(e) => {
                    Tracking.trackEngagement('home/showtimes/select-day', Days.difference(day, today).toString())
                    onSelectDay(day)
                  }}>
                  {Days.toPrettyString(day, today)}
                </LinkItem>
           )
         }
        </List>
        <Row>
          <ListContainer>
            { cinemas.map ((c, idx) =>
              <SubSection key={c.name} renderTitle={() =>
                <ShowtimeHeader>
                  <Subtitle>{c.name}</Subtitle>
                  <CinemaSubtitle>{cinemaMovies[idx].message}</CinemaSubtitle>
                </ShowtimeHeader>
              }>
                <Fader key={c.name + Days.toString(selectedDay)}>
                  <MovieShowList now={now} movies={cinemaMovies[idx].movies} highlight={highlight}
                    skipMoviesNotShowingToday={true}
                    onHighlight={onHighlightMovie}
                    onSelect={(m) => {
                      if (isMobile()) {
                        Tracking.trackEngagement('home/showtimes/detail-movie', m.title, c.id)
                        onHighlightMovie(m)
                        return false  // block navigation
                      } else {
                        Tracking.trackNavigation('home/showtimes/select-movie', m.title, c.id)
                        return true  // navigate
                      }
                    }}
                    getShows={getShowsOnDay(selectedDay)}
                    noShowsMessage={c.noShowsMessage}/>
                </Fader>
              </SubSection>
            )}
          </ListContainer>
          {
            isSSR()
              ? null
              : isMobile()
                  ? <MovieDrawer highlight={highlight} mainCinemaId={cinemas[0].id}
                      onClick={() => {onHighlightMovie(null)}}/>
                  : <Panel>
                      { highlight == null
                        ? null
                        : <MovieSummary key={highlight.id} mainCinemaId={cinemas[0].id}
                            movie={highlight}
                            renderDescription={truncateDescription}/>
                      }
                    </Panel>
            }
        </Row>
      </Section>
      }
      { showEventsReel && events.length > 0 &&
        <Section title="Eventi" linkTo="/eventi">
          <MovieList look='slider2' lazy={true}
            items={MovieVM.computeMovieListForEvents(cinemas, events, today)}
            onCardClick={(item, idx) => {Tracking.trackNavigation('home/events-list', item.movie.title, idx.toString())}}/>
        </Section>
      }
      { showComingSoonReel && comingSoon.length > 0 &&
       <Section title="Prossimamente" linkTo="/prossimamente">
          <MovieList look='grid' lazy={true}
            items={MovieVM.computeMovieListForComingSoon(cinemas, comingSoon, today)}
            onCardClick={(item, idx) => {Tracking.trackNavigation('home/coming-soon-list', item.movie.title, idx.toString())}}/>
        </Section>
      }
    </Page>
  </Layout>
)}

const isShowingToday = (movieId: MovieId, cinemas: CinemaDay[]): boolean => {
  return cinemas
    .reduce((acc, c) => [...acc, ...c.movies], [])
    .some(m => m.id === movieId)
}

const mapModelToProps: (state: Model, staticProps: StaticProps) => PageProps = (model, ownProps) => {
  if (model.page.id !== 'home') {
    throw new Error('Unexpected state when computing Home props: ' + model.page.id)
  }
  const today = Days.fromMoment(model.now)
  const activeDays = Movies.getPresentAndFutureDays(today, [...ownProps.cinemas[0].movies, ...ownProps.cinemas[1].movies])

  const selectedDay = model.page.selectedDay 
  const cinemaMovies: CinemaDay[] = ownProps.cinemas.map( c => {
    const selectedDayMovies = Movies.groupByDay(c.movies)[Days.toString(selectedDay)] || []
    const dayShows = selectedDayMovies.reduce(
      (acc, m) => acc.concat(...m.shows[Days.toString(selectedDay)]),
      [] as Show[]
    )
    const dayTags = ShowsVM.getCommonTags(dayShows)

    return {
      message: dayTags.length > 0
        ? dayTags[0] === '€'
          ? CINEMA_META[c.id].promotionDayMessage
          : dayTags[0]
        : null,
      movies: selectedDayMovies.sort(Movies.sortNowShowingMovies(selectedDay))
    }
  })

  const highlightId = isSSR()
    ? null
    : model.page.highlight == null
      ? isMobile()
        ? null
        : cinemaMovies[0].movies[0] && cinemaMovies[0].movies[0].id
      : isShowingToday(model.page.highlight.id, cinemaMovies)
        ? model.page.highlight.id
        : cinemaMovies[0].movies[0] && cinemaMovies[0].movies[0].id

  const highlight = R.find(m => m.id === highlightId, model.nowShowing) || null

  const props = {
    nowShowing: model.nowShowing,
    comingSoon: model.comingSoon,
    events: model.events,
    cinemas: ownProps.cinemas,
    highlight: highlight,
    selectedDay,
    activeDays,
    now: model.now,
    cinemaMovies
  }
  return props
}

const mapDispatchToIntents = (dispatch: Dispatch<Action>) => ({
    onHighlightMovie: (movie: Movie): void  => {
      dispatch(Actions.highlightMovie(movie))
    },
    onSelectDay: (day: Day) => {
      dispatch(Actions.selectDay(day))
    }
})

const ConnectedPage = connect(
  mapModelToProps,
  mapDispatchToIntents
)(IndexPage as any)

const StaticPage: React.SFC<any> = ( query ) => {
  const { mainCinemaId, cinemas } = extractData(query)
  const store = createStore('home', cinemas, mainCinemaId, true)

  return (
    <ThemeProvider theme={THEMES[mainCinemaId]}>
      <Provider store={store}>
        <>
          <ConnectedUpdater/>
          <ConnectedPage cinemas={cinemas} />
        </>
      </Provider>
    </ThemeProvider>
  )
}

export default StaticPage

export const query = graphql`
  query HomePageQuery {
    ...alldata
  }
`

